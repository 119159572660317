<template>
  <div class="main-layout">
    <div class="container">
      <div class="questionBox" id="app">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    let utm_data = this.$cookies.get('utm_data') ?? {};
    for (let t of ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']) {
      if (urlParams.has(t)) {
        utm_data[t] = urlParams.get(t);
      }
    }

    this.$cookies.set('utm_data', utm_data, 30 * 24 * 60 * 60, '/', '.' + window.location.hostname);
  }
}
</script>

<style  >

.main-layout {
  
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom left;
  background-image: url('@/assets/images/hero-bg.svg');
  
  padding-top: 4em;
  padding-bottom: 8rem;
  
  min-height: 100vh;
  background-color: #f8f9fa;
}



@media screen and (min-width: 769px) {
  .main-layout .container{
    margin-left: auto;
    margin-right: auto;
    max-width: 740px;
  }
}

@media screen and (max-width: 768px) {
  
}


</style>
